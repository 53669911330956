import { JsonRpcRequest, JsonRpcResponse, ParticleRpcRequest, RequestArguments } from '@particle-network/auth';
import { EventEmitter } from 'events';

export class ProviderError extends Error {
    constructor(public code: number, public message: string, public data?: unknown) {
        super(message);
        this.code = code;
        this.message = message;
        this.data = data;
    }

    public static userRejectedRequest() {
        return new ProviderError(4001, 'The user rejected the request');
    }

    public static userCancelOperation() {
        return new ProviderError(4011, 'The user cancel the operation');
    }

    public static unauthorized() {
        return new ProviderError(4100, 'The requested method and/or account has not been authorized by the user');
    }

    public static unsupportedMethod() {
        return new ProviderError(4200, 'The Provider does not support the requested method');
    }

    public static unsupportedChain() {
        return new ProviderError(4201, 'The Provider does not support the chain');
    }

    public static disconnected() {
        return new ProviderError(4900, 'The Provider is disconnected from all chains');
    }

    public static chainDisconnected() {
        return new ProviderError(4901, 'The Provider is not connected to the requested chain');
    }

    public static paramsError() {
        return new ProviderError(8002, 'Param error, see doc for more info');
    }
}

export interface ProviderMessage {
    type: string;
    data: unknown;
}

export interface ProviderConnectInfo {
    chainId: string;
}

export type ProviderChainId = string;

export type ProviderAccounts = string[];

export interface EIP1102Request extends RequestArguments {
    method: 'eth_requestAccounts';
}

export interface ConnectionConfig {
    url: string;
    basicCredentials: string;
    chainId: () => number;
    authentication: ParticleAuthentication;
}

export interface ParticleAuthentication {
    projectId: string;
    clientKey: string;
}

export abstract class IEvents {
    public abstract events: EventEmitter;

    // events
    public abstract on(event: string, listener: any): this;
    public abstract once(event: string, listener: any): this;
    public abstract off(event: string, listener: any): this;
    public abstract removeListener(event: string, listener: any): this;
}

export abstract class IJsonRpcConnection extends IEvents {
    public events = new EventEmitter();

    public abstract connected: boolean;
    public abstract connecting: boolean;

    public on(event: string, listener: any) {
        this.events.on(event, listener);
        return this;
    }

    public once(event: string, listener: any) {
        this.events.once(event, listener);
        return this;
    }

    public off(event: string, listener: any) {
        this.events.off(event, listener);
        return this;
    }

    public removeListener(event: string, listener: any) {
        this.events.removeListener(event, listener);
        return this;
    }

    public abstract open(): Promise<void>;
    public abstract close(): Promise<void>;
    public abstract send(request: ParticleRpcRequest): Promise<JsonRpcResponse>;
}

export abstract class IJsonRpcProvider extends IEvents implements IEthereumProvider {
    public events = new EventEmitter();
    public on(event: string, listener: any) {
        this.events.on(event, listener);
        return this;
    }

    public once(event: string, listener: any) {
        this.events.once(event, listener);
        return this;
    }

    public off(event: string, listener: any) {
        this.events.off(event, listener);
        return this;
    }

    public removeListener(event: string, listener: any) {
        this.events.removeListener(event, listener);
        return this;
    }

    abstract request(request: Partial<JsonRpcRequest>): Promise<any>;
}

export interface IEthereumProvider {
    // connection event
    on(event: 'connect', listener: (info: ProviderConnectInfo) => void): this;
    // disconnection event
    on(event: 'disconnect', listener: (error?: ProviderError) => void): this;
    // arbitrary messages
    on(event: 'message', listener: (message: ProviderMessage) => void): this;
    // chain changed event
    on(event: 'chainChanged', listener: (chainId: ProviderChainId) => void): this;
    // accounts changed event
    on(event: 'accountsChanged', listener: (accounts: ProviderAccounts) => void): this;

    on(event: string, listener: any): this;

    once(event: string, listener: any): this;

    off(event: string, listener: any): this;

    removeListener(event: string, listener: any): this;

    request(request: Partial<JsonRpcRequest>): Promise<any>;
}

export interface IAuthAdapter {
    request(request: Partial<JsonRpcRequest>): Promise<any>;
}

export const notSupportMethods = ['eth_signTransaction', 'eth_sign', 'eth_sendRawTransaction'];

export const signerMethods = [
    'eth_requestAccounts', //EIP-1102
    'eth_accounts',
    'eth_chainId',
    'eth_sendTransaction',
    'eth_signTypedData',
    'eth_signTypedData_v1',
    'eth_signTypedData_v3',
    'eth_signTypedData_v4',
    'personal_sign',
    'wallet_switchEthereumChain', //EIP-3326
    'wallet_addEthereumChain', //EIP-3085
    'wallet_watchAsset', //EIP-747
];

export const particleSignerMethods = [
    'personal_sign_uniq', //particle mpc signature uniq
    'eth_signTypedData_v4_uniq', //particle mpc signature uniq
];
